<template>
  <n-card
    style=""
    class="bg-beige shadow-lg rounded-2xl hover:shadow  max-w-lg my-8"
  >
    <div style="float: right">
      <n-h4 style="margin: 0px 0px; text-align: right"
        ><n-text strong type="primary">{{ Math.round(course.rating * 10) / 10  }}<span class="fa fa-star text-lg text-orange"></span></n-text></n-h4
      >
      <n-text depth="3" style="margin: -4px 0px"
        >from
        <n-text type="primary" strong>{{ course.sessions.length }} </n-text>
        sessions</n-text
      >
    </div>
    <div style="margin-top: -3px">
      <n-text type="primary" depth="3" style="font-size: 12px; margin: 0px 0px"
        >{{this.tutor_previewed.in_person? 'online & in-person': 'online'}}</n-text
      >
      <n-h3 style="margin: -2px 0px -1px 0px"
        ><n-text class="capitalize">{{ course.class.name }}</n-text></n-h3
      >
    </div>

    <n-text depth="3">{{ course.class.desc }}</n-text>
    <n-divider style="margin: 7px 0px"></n-divider>
    <div class="float-right w-32 flex flex-wrap">
      <div class="flex w-32 items-center">
        <p class="mr-2">grade <span class="uppercase text-orange font-bold">{{ course.grade }}</span></p>
        <div
          v-if="course.verified"
          class="
            w-4
            h-4
            bg-orange
            rounded-full
            ring-2 ring-orange ring-offset-1
            flex
            items-center
            justify-center
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            class="w-3 h-3"
            viewBox="0 0 172 172"
            style="fill: #000000"
          >
            <g
              fill="none"
              fill-rule="nonzero"
              stroke="none"
              stroke-width="1"
              stroke-linecap="butt"
              stroke-linejoin="miter"
              stroke-miterlimit="10"
              stroke-dasharray=""
              stroke-dashoffset="0"
              font-family="none"
              font-weight="none"
              font-size="none"
              text-anchor="none"
              style="mix-blend-mode: normal"
            >
              <path d="M0,172v-172h172v172z" fill="none"></path>
              <g fill="#ffffff">
                <path
                  d="M69.56683,137.03383c-1.34017,1.34733 -3.16767,2.09983 -5.06683,2.09983c-1.89917,0 -3.72667,-0.7525 -5.06683,-2.09983l-40.463,-40.47017c-4.19967,-4.19967 -4.19967,-11.008 0,-15.2005l5.06683,-5.06683c4.19967,-4.19967 11.00083,-4.19967 15.2005,0l25.2625,25.2625l68.2625,-68.2625c4.19967,-4.19967 11.008,-4.19967 15.2005,0l5.06683,5.06683c4.19967,4.19967 4.19967,11.008 0,15.2005z"
                ></path>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <p class="w-32 truncate">with <span class="font-bold">Dr. {{ course.professor }}</span></p>
    </div>
    <n-text type="primary" strong
      >{{ course.students.length }}<n-text> Students</n-text></n-text
    >
    <students :imgs="imgs" />
    <div class="mt-2 flex justify-around">
      <button
        class="w-36 text-orange font-bold capitalize"
        @click="
          () => router.push(`/classes/${course._id}`)
        "
      >
        more info
      </button>
      <n-button type="primary" round class="w-36" @click="takeClass()"><span class="font-bold capitalize">take class</span> </n-button>
    </div>
  </n-card>
</template>


<script>
import {
  NText,
  NCard,
  
  NH4,
  NH3,
  NButton,
  NDivider,
  
} from "naive-ui";
import { useRouter } from "vue-router";
import Students from './students.vue';
import { mapMutations, mapState } from 'vuex';
export default {
  components: {
    NText,
    NH4,
    NH3,
    NButton,
    NDivider,
    NCard,
    Students,
  },
  methods: {
    ...mapMutations(['SetSessionPreviewClass']),
    takeClass(){
      this.SetSessionPreviewClass(this.course);
      this.$router.push(`/tutor/${this.tutor_previewed.user.username}/book/time`)
    }
  },
  props: {
    course: Object,
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  computed: {
    ...mapState(['tutor_previewed']), 
    imgs() {
      let images = [];
      for (let index = 0; index < this.course.students.length; index++)
        images.push(this.course.students[index].img_url);  
      return images;
    }
  },
};
</script>