<template>
  <page-layout title="Tutor" :linkDefault="`/u/${tutor_previewed.user.username}`">
    <div v-if="!tutor_previewed">...</div>
    <n-card v-else class="outline-none border-none">
      <n-avatar
        class="mx-auto"
        round
        :src="
          tutor_previewed.user.img_url
            ? tutor_previewed.user.img_url
            : filler_img
        "
        :size="54"
      />
      <n-space align="flex-end">
        <div>
          <h2 class="font-bold text-lg lg:text-xl">
            {{ tutor_previewed.user.name }}
          </h2>
          <div style="display: inline-flex">
            <n-rate
              readonly
              :default-value="parseInt(tutorCourseMeta.rating + 0.49)"
            />
            <n-tag
              type="success"
              round
              style="margin-left: 25px; margin-top: -2px"
              >{{ tutor_previewed.rate }} EGP</n-tag
            >
          </div>
          <p class="font-extralight max-w-prose">
            {{ tutor_previewed.user.bio }}
          </p>
        </div>
      </n-space>
      <br />
      <div
        class="left-5 right-5 px-2 shadow-lg h-14 absolute rounded-3xl"
      ></div>
      <n-tabs
        type="bar"
        justify-content="space-evenly"
        size="large"
        tab-style="font-weight: bold; padding: 16px 12px; margin: auto"
      >
        <n-tab-pane name="Classes" tab="Classes">
          <transition-group tag="ul" name="scale-up" appear class="relative">
            <li v-for="(course, index) in tutor_previewed.courses" :key="index">
              <tutor-class class="mx-auto" :course="course"></tutor-class>
            </li>
          </transition-group>
        </n-tab-pane>
        <n-tab-pane name="Reviews" tab="Reviews">
          <tutor-profile-component
            :tutor="tutor_previewed"
          ></tutor-profile-component>
        </n-tab-pane>
      </n-tabs>
      <transition name="fade-up-left" appear>
        <button
          class="
            fixed
            right-5
            bottom-24
            bg-orange
            rounded-full
            px-8
            text-lg
            py-3
            text-white
            font-bold
            shadow-lg
            capitalize
          "
          @click="book()"
        >
          book!
        </button>
      </transition>
    </n-card>
  </page-layout>
</template>

<script>
import { NCard, NTabs, NTabPane, NSpace, NAvatar, NRate, NTag } from "naive-ui";
import TutorProfileComponent from "../components/TutorProfile.vue";
import TutorClass from "../components/TutorClass.vue";
import PageLayout from "../components/base/pageLayout.vue";
import { useRoute, useRouter } from "vue-router";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import mixpanel from "mixpanel-browser";
import { readFromLocalStorage } from "../helpers";

export default {
  name: "TutorProfile",
  components: {
    NCard,
    NTabs,
    NTabPane,
    NSpace,
    NAvatar,
    NRate,
    NTag,
    PageLayout,
    TutorProfileComponent,
    TutorClass,
  },
  computed: {
    ...mapState([
      "tutor_previewed",
      "filler_img",
      "session_previewed",
      "class_previewed",
    ]),
    ...mapGetters(["getTutorCourseMeta"]),
    tutorCourseMeta() {
      return this.getTutorCourseMeta(this.tutor_previewed);
    },
  },
  created() {
    // let localTutor = readFromLocalStorage("session_tutor", true);
    // if (!!localTutor && localTutor.user?.username === this.username)
    //   this.setTutorPreviewed(localTutor);
    this.getTutor();
    mixpanel.track("visited tutor profile", {
      tutor: this.username,
      class: this.session_previewed.class,
    });
  },
  methods: {
    ...mapActions(["get_tutor"]),
    ...mapMutations(["SetSessionPreviewClass", "setTutorPreviewed"]),
    async getTutor() {
      await this.get_tutor(this.username);
    },
    book() {
      if (this.class_previewed) {
        const course = this.class_previewed.courses.filter(
          (course) => course.tutor._id == this.tutor_previewed._id
        )[0];
        this.SetSessionPreviewClass(course);
        this.$router.push(
          `/tutor/${this.tutor_previewed.user.username}/book/time`
        );
      } else
        this.$router.push(
          `/tutor/${this.tutor_previewed.user.username}/book/class`
        );
    },
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { username } = route.params;
    return { username, router };
  },
};
</script>