<template>
  <div>
    <!-- <n-divider title-placement="left">Students</n-divider> -->
    <br />
    <div style="margin: 0px 20px">
      <div style="float: right">
        <n-text type="primary" strong>{{ tutorCourseMeta.num_sessions }}<n-text> Sessions</n-text>
        </n-text>
      </div>
      <n-text type="primary" strong>{{ tutorCourseMeta.num_students }}<n-text> Students</n-text>
      </n-text>
      <students :imgs="tutorCourseMeta.student_imgs" />
    </div>
    <n-divider title-placement="left">Reviews</n-divider>
    <empty v-if="!tutorCourseMeta.reviews || tutorCourseMeta.reviews.length === 0" text="no reviews yet" />
    <transition-group v-else v-for="(review, index) in tutorCourseMeta.reviews" :key="index" tag="ul" name="scale-up"
      appear class="flex flex-wrap justify-start">
      <li>
        <tutor-review :review="review" />
      </li>
    </transition-group>
  </div>
</template>

<script>
import { NDivider, NText } from "naive-ui";
import TutorReview from "./TutorReview.vue";
import { mapGetters } from "vuex";
import Students from "./students.vue";
import Empty from "./empty.vue";
export default {
  components: {
    NDivider,
    NText,
    TutorReview,
    Students,
    Empty,
  },
  props: {
    tutor: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["getTutorCourseMeta"]),
    tutorCourseMeta() {
      return this.getTutorCourseMeta(this.tutor);
    },
  },
};
</script>